
import { reactive, toRefs, watch} from 'vue'
import { isValid, machineOutBillList, machineBillDetail } from '../../utils/api'
import { geneIndex, formatTime, showMessage } from '../../utils/util'
export default {
    name: '',
    setup() {
        const state = reactive({
            formItems: [
                 {type: 'input', label: '订单ID', value: null},
                 {type: 'input', label: '操作员ID', value: null},
                 {type: 'selector', label: '状态', key: 'value', options: [{label: '进行中', value: 0}, {label: '正常', value: 10}, {label: '取消', value: 99}]},
                 {type: 'button', label: '搜索', eventName: 'loadData'},
                //  {type: 'button', label: '创建入库单', eventName: 'addNew'},
             ],
             columns: [
                 {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',  
                 },
                  {
                    title: '操作员',
                    dataIndex: 'sysUserName',
                    key: 'sysUserName',  
                 },
                 {
                    title: '订单ID',
                    dataIndex: 'orderId',
                    key: 'orderId',  
                 },
                 {
                    title: '订单创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',  
                 },
                  {
                    title: '设备总数',
                    dataIndex: 'number',
                    key: 'number',
                 },
                 {
                    title: '状态',
                    dataIndex: 'statusText',
                    key: 'statusText',
                 },
                  {
                        title: '操作',
                        dataIndex: 'manage',
                        key: 'manage',
                        slots: {customRender: 'actions'}
                },
             ],
             currentPage: 1,
             pageSize: 10,
             detailCurrentPage: 1,
             detailPageSize:  1000,
             tableList: [],
             loading: true,
             buttons: [
                 {text: '详情', eventName: 'inDetail'},
             ],
             billForms: [
                 {label: '入库数量', value: '', type: 'input', name: 'inNum'},
             ],
             billFormsName: {
                 inNum: ''
             },
             machineList: [
                 {num: '14552358568654545', isChoosed: false},
                 {num: '4768454257487676', isChoosed: false},
                 {num: '45642514245456456', isChoosed: false},
                 {num: '452456746354345', isChoosed: false}
            ],
             choosedMachine: [],
             showBillDialog: false,
             billLoading: false,
             billTitle: '单据详情',
             detailTitle: '出库单详情',
             showDetailDialog: false,
             detailLoading: false,
             currentBillId: 0,
             currentBillStatus: 0,
             detailList: [],
             machineId: '',
             machineNum: 0,
             billDialogType: 0, // 0：新建 1：修改 
             cancelText: '取消',
             confirmText: '创建',
             total: 0,
             detailDisable: false,
             detailInfo: {},
             isCreate: true
        })

        const statusText = [
            [0, '进行中'],
            [10, '正常'],
            [99, '取消']
        ]
        const statusMap = new Map(), textMap = new Map()
        // 初始化map
        function initMap() {
            statusText.forEach(ele => {
                statusMap.set(ele[0], ele[1])
                textMap.set(ele[1], ele[0])
            })
        }
        initMap()
        // 获取map对应值
        function getMapValue(key: any) {
            // console.log(statusMap, textMap)
            return textMap.has(key) ? textMap.get(key) 
                 : statusMap.has(key) ? statusMap.get(key) : undefined
        }
        // 把状态由数字转化成可读文字
        function changeStatus(data: any) {
            data.forEach((ele: any) => {
                ele.statusText = getMapValue(ele.status)
                ele.disable = ele.status != 0
            })
        }
        
        // 修改时间戳为可读时间
        function transferTime(data: any) {
            data.forEach((ele: any) => {
                ele.createTime = formatTime(ele.createTime)
            })
        }
        // 将可读文字转化为数字
        function changeTextToNum(text: any) {
            return textMap.get(text)
        }
        // 选择设备
        function chooseMachine(item: any) {
            item.isChoosed = !item.isChoosed
        }
        // 删除设备
        function deleteMachine() {
            const _list = state.machineList.filter(ele => !ele.isChoosed)
            state.machineList = _list
        }
        async function loadData(currrentPage=null) {
            try {
                state.currentPage = currrentPage || state.currentPage
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    orderId: state.formItems[0].value,
                    sysUserId: state.formItems[1].value,
                    status: state.formItems[2].value
                }
                const result = await machineOutBillList(params)
                if (isValid(result)) {
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.total = result.data.total
                    changeStatus(state.tableList)
                    transferTime(state.tableList)
                    state.loading = false
                }
            } catch(e) {
                console.error(e)
            }
        }
         // 创建订单
        // async function finishBill( ) {
        //     try {
        //         const ids = state.machineList.map(ele => ele.num)
        //         const params = {
        //             number: ids.length,
        //             deviceNos: ids.join(",")
        //         }
        //         const result = await createMachineInOutBill(params)
        //         if (result.code == 0) {
        //             showMessage('入库单创建完成')
        //             state.showDetailDialog = false
        //             loadData()
        //         }
        //     } catch(e) { 
        //         console.error(e)
        //     }
        // }
        // 详情确认按钮
        async function machineHandle() {
           state.showDetailDialog = false
            // if (state.currentBillStatus == 0) {
            //     finishBill()
            // } else {
            //     state.showDetailDialog = false
            // }
        }
        // 新建入库单
        async function addNew() {
            state.machineList = []
            state.billDialogType = 0
            state.showDetailDialog = true
            state.isCreate = true
        }
        // 订单明细
        async function inDetail(event?: any) {
            try {
                const params = {
                    orderId: event.orderId
                }
                const result = await machineBillDetail(params)
                if (result.code == 0) {
                    console.log(result)
                    state.detailInfo = result.data
                    let machines = [
                        {num: '14552358568654545', isChoosed: false},
                    ]
                    machines = []
                    result.data?.items.forEach((ele: any) => {
                        machines.push({num: ele.deviceNo, isChoosed: false})
                    })
                    state.machineList = machines
                    state.isCreate = false 
                    state.showDetailDialog = true
                }

            } catch(e) {
                console.error(e)
            }
        }
        // 设备入库
        async function inStock (machineId: any) {
            try {
                if (machineId == '' || machineId == undefined) {
                    showMessage('请先输入设备号', 'warn')
                    return
                }
                state.machineList.push({isChoosed: false, num: machineId})
                state.machineId = ''
            } catch(e) {
                console.error(e)
            }
        }
        watch(() => state.showDetailDialog, (showDetailDialog) => {
            if (!showDetailDialog) {
                loadData()
            }
        })
        loadData()
        return {
            ...toRefs(state),
            inDetail,
            inStock,
            // finishBill,
            addNew,
            machineHandle,
            loadData,
            chooseMachine,
            deleteMachine,
            formatTime,
            getMapValue,
        }
    }
}
